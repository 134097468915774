<template>
  <EntityList
    :config="config"
    :columns="columns"
    :formatted-columns="formattedColumns"
    :extra-actions="extraActions"
    :has-insert-btn="false"
    :hide-edit="true"
    :allow-duplicate="false"
    authority="Users_User_Request"
    inline-actions
    single-edit
  />
</template>

<script>
import EntityList from '@/layouts/entity/EntityList.vue'

import storeModule from '../userRequestStoreModule'

export default {
  components: {
    EntityList,
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'user-requests',
        endpoint: 'user-requests',
        route: 'user-requests',
        title: {
          single: this.$t('User Request'),
          plural: this.$t('User Requests'),
        },
      }
    },

    columns() {
      return [
        { key: 'id', label: this.$t('id'), sortable: true },
        { key: 'approved', label: '' },
        { key: 'firstName', label: this.$t('First Name'), sortable: true },
        { key: 'lastName', label: this.$t('Last Name'), sortable: true },
        { key: 'email', label: this.$t('Email'), sortable: true },
        { key: 'actions', label: this.$t('actions') },
      ]
    },

    formattedColumns() {
      return [{ key: 'approved', type: 'status' }]
    },

    extraActions() {
      return [
        {
          callback: 'getEditLink',
          icon: 'CheckCircleIcon',
          title: 'Approve',
          renderCondition: {
            key: 'approved',
            value: false,
          },
        },
      ]
    },
  },
}
</script>
